<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-toolbar-title>Блоги</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn :to="{ name: 'CabinetBlogAdd' }" text tile> Создать блог </v-btn>
      <v-menu transition="slide-y-transition" left bottom offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text tile v-on="on">
            <span class="d-none d-sm-flex mr-1">Сортировка</span>
            <v-icon>$vuetify.icons.sort</v-icon>
          </v-btn>
        </template>
        <v-list class="text-right">
          <v-list-item-group color="primary">
            <!-- class="active v-list-item--active" -->
            <v-list-item
              v-for="(item, index) in sortings"
              :key="index"
              :class="item.val === sort ? 'active v-list-item--active' : ''"
              @click="sortCategory(item.val)"
            >
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-toolbar>
    <!-- <v-container v-if="!loadPage"> -->
      <banner index="0"></banner>
    <v-container>
      <v-row align="center">
        <no-content
          v-if="blogs.length == 0"
          route-name
          title="Блогов нет"
        ></no-content>
        <blog-card
          v-for="(blog, index) in blogs"
          :key="blog.id"
          :blog="blog"
          :index="index"
          :library="false"
          :admin="false"
          :cabinet="false"
        ></blog-card>
      </v-row>
      <v-row align="center">
        <v-col cols="12">
          <v-pagination
            v-if="pages > 1"
            @input="goPage"
            v-model="page"
            :length="pages"
            :total-visible="7"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>
    <banner index="1"></banner>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BlogCard from "./BlogCard.vue";
import Banner from "../Banner.vue";
export default {
  components: {
    BlogCard,
    Banner
  },
  data: () => ({
    sort: "last_comment",
    sortings: [
      { text: "По активности", val: "last_comment" },
      { text: "По новизне", val: "new" },
      { text: "По популярности", val: "views" },
      { text: "По комментариям", val: "comments" },
    ],
    page: 1,
    pages: 0,
    loading: false,
  }),
  metaInfo() {
    if (this.categoryTitle) {
      return {
        title: `Литературные блоги и статьи на Bookstab.ru`,
        meta: [
          {
            name: "description",
            content:
              "Читайте литературные блоги авторов и писателей. На Bookstab.ru вас ждет самая правдивая литературная критика в интернете.",
          },
          {
            property: "og:title",
            content: "Литературные блоги и статьи на Bookstab.ru",
          },
          { property: "og:site_name", content: "Bookstab" },
          {
            property: "og:description",
            content:
              "Читайте литературные блоги авторов и писателей. На Bookstab.ru вас ждет самая правдивая литературная критика в интернете.",
          },
          { property: "og:type", content: "blog" },
          { property: "og:url", content: window.location.href },
        ],
      };
    }
    return {};
  },
  methods: {
    getBlogs() {
      var app = this;

      this.$store
        .dispatch("blog/getBlogs", {
          page: app.page,
          sort: app.sort,
          type: app.type,
        })
        .then((response) => {
          // app.loading = false;
          if (response.data.success == 1) {
            app.pages = response.data.blogs.last_page;
          }
        });
    },
    goPage(nextPage) {
      // console.log('nextPage',nextPage);
      //   console.log(this.$route.query.page);
      if (nextPage != this.$route.query.page) {
        this.$router.push({
          name: this.$route.name,
          query: { page: nextPage, sort: this.sort },
        });
      }
    },
    sortCategory(type) {
      if (type != this.sort) {
        this.sort = type;
        this.$router.push({
          name: this.$route.name,
          query: { page: this.page, sort: this.sort },
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      blogs: "blog/blogs",
      blogsSort: "blog/blogSort",
    }),
  },
  created() {
    this.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
    this.sort = this.$route.query.sort
      ? this.$route.query.sort
      : "last_comment";
    this.getBlogs();
  },
};
</script>
